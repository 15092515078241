pcg-table {
	margin-top: 5px;
	display: block;
}

.pcg-table-container .data-grid { margin-bottom: $spacer; }
.pcg-table-container .data-grid { margin-bottom: 0; }

.pcg-table-responsive {
	max-height: 400px;
	width: 100%;
	overflow: auto;
}

.pcg-table-responsive.pcg-table-fixed-header thead tr th,
.pcg-table-responsive.pcg-table-fixed-header thead tr { top: 0; }

.pcg-table-fixed-header thead tr th {
	position: sticky;
	z-index: 2;
}

.pcg-table-fixed-header thead tr {
	position: sticky;
	z-index: 2;
}

.pcg-table-responsive.pcg-table-fixed-footer tfoot tr td, 
.pcg-table-responsive.pcg-table-fixed-header tfoot tr {
	position: sticky;
	bottom: 0;
	z-index: 2;
}

.data-grid.cdk-table { margin-bottom: initial; }

.data-grid {
	// Basic table definition, copied from bootstrap
	// ***************************************
	width: 100%;
	margin-bottom: $spacer;
	background-color: $table-bg; // Reset for nesting within parents with `background-color`.
	th.text-right .pcg-sort-header-container:not(:hover):not(.pcg-sort-header-sorted) { padding-left: 18px; }
	th.text-right .pcg-sort-header-container:not(:hover):not(.pcg-sort-header-sorted) .pcg-sort-header-arrow { display: none; }
	th.text-right > .pcg-sort-header-container:hover > button + .pcg-sort-header-arrow { display: flex; }
	th.text-right > .pcg-sort-header-container { justify-content: flex-end; }
	th.text-right > .pcg-sort-header-container button { text-align: right; }
	th.text-center .pcg-sort-header-container:not(:hover):not(.pcg-sort-header-sorted) { padding-right: 18px; }
	th.text-center .pcg-sort-header-container:not(:hover):not(.pcg-sort-header-sorted) .pcg-sort-header-arrow { display: none; }
	th.text-center > .pcg-sort-header-container:hover > button + .pcg-sort-header-arrow { display: flex; }
	th.text-center > .pcg-sort-header-container { justify-content: center; }
	th.text-center > .pcg-sort-header-container button { text-align: center; }
	.cdk-column-noRecords { text-align: center; }
	th > .pcg-sort-header-container button { text-align: left; }
	tr.search-row th { padding: 0 $table-cell-padding 0.5rem $table-cell-padding; }

	th, td {
		padding: $table-cell-padding;
		vertical-align: middle;
	}

	tbody td { border: $table-border-width solid $table-border-color; }
	.pcg-sort-header-button { outline: 0; }
	th[pcg-sort-header] { cursor: pointer; }

	thead th {
		vertical-align: bottom;
		font-weight: $font-weight-bold;
	}

	tbody + tbody { border-top: (2 * $table-border-width) solid $table-border-color; }
	.table { background-color: $body-bg; }
	// Stripe table rows
	tbody tr:nth-of-type(#{$table-striped-order}) { background-color: $table-accent-bg !important; }
	tbody tr.parent { background-color: $table-accent-bg !important; }
	// TODO: make a variable?
	tbody tr.child-1 { background-color: #FBFBFB !important; }
	// TODO: make a variable?
	tbody tr.child-2 { background-color: #FDFDFD !important; }
	// Add table hover effect
	tbody tr { @include hover { background-color: $table-hover-bg !important; } }
	// Header and footer styling
	thead, tfoot, thead th, tfoot td {
		background-color: $table-header-bg;
		color: $table-header-text-color;
	}
}

.pcg-table-responsive .sticky-col {
	position: sticky;
	left: 0;
	border-collapse: separate;
}

.pcg-table-responsive.pcg-table-fixed-footer tfoot tr td.sticky-col,
.pcg-table-responsive.pcg-table-fixed-footer thead tr th.sticky-col {
	background-color: $table-header-bg;
	color: $table-header-text-color;
	z-index: 3;
}

.pcg-table-responsive tbody td.sticky-col {
	border: $table-border-width solid $table-border-color;
	background-color: white;
	background-clip: padding-box;
}

.pcg-table-responsive tbody td.sticky-col:after {
	content:'';
	position:absolute;
	right: 0;
	top: 0;
	height:100%;
	border-right: 1px solid rgba(0,0,0,0.12);
}

.pcg-table-responsive tr:hover td.sticky-col { background-color: $table-hover-bg; }

.pcg-table-responsive tbody tr:nth-of-type(#{$table-striped-order}) td.sticky-col {
	background-color: $table-accent-bg;
	border: $table-border-width solid $table-border-color;
}

.pcg-table-responsive tbody tr:nth-of-type(#{$table-striped-order}):hover td.sticky-col { background-color: $table-hover-bg; }

// The expand-button-container is used for indentation of nested expand buttons
td.expand-button-container {
	display: flex;
	justify-content: center;

	.expand-button {
		margin: 0;
		margin-left: 20%;
	}
}

// Subrow CSS
.expand-button, button.p-element.p-treetable-toggler {
	border-radius: 1em;
	width: 2em;
	height: 2em;
	line-height: 2em;
	text-align: center;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	box-shadow: 0px 0px 0.2em;
	font-weight: bold;
}

.expand-button:active { box-shadow: 0px 0px 0.2em rgba(0, 0, 0, 0.2); }
.detail-expand { padding: 0; }

// override default angular material table styles
.mdc-data-table__cell {
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji" !important;
	font-size: 1rem !important;
	font-weight: 400 !important;
	line-height: 1.5 !important;
}

.mdc-data-table__content {
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji" !important;
	font-size: 1rem !important;
	font-weight: 400 !important;
	line-height: 1.5 !important;
}

.mdc-data-table__cell {
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji" !important;
	font-size: 1rem !important;
	font-weight: 400 !important;
	line-height: 1.5 !important;
}

.mdc-data-table__header-cell {
	font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji" !important;
	font-size: 1rem !important;
	font-weight: 600 !important;
	line-height: 1.5 !important;
}

.mdc-data-table__row:last-child .mdc-data-table__cell { border-bottom: 1px solid #dee2e6 !important; }

.mdc-data-table__header-cell {
	border: none !important;
	background-color: $table-header-bg !important;
	color: $table-header-text-color !important;
}

//Table Elements Edit Links
pcg-edit-link {
    color: #0062cc;
    text-decoration: none;
    background-color: transparent;
}

pcg-edit-link:hover {
    color: #003d80;
    cursor: pointer;
    text-decoration: underline;
}
