.main-container {
	flex: 1;
	display: flex;
}

.top-nav-bg {
	background-color: $main-navbar-bg-color;
}

.side-nav-content {
	background: rgba(0,0,0,.03);
	padding: 5px;
	overflow-y: auto;
	max-height: 550px;
	flex-direction: column;
}

pcg-side-nav {
	flex: 1;
	flex-direction: column;
	display: flex;
	max-width: 200px;
	border-right: 1px solid #ccc;
	padding: 10px;
	margin-top: 10px;
	position: sticky;
	width: 200px;
	height: 600px;

	svg {
		width: 25px;
		height: 25px;
	}
}

pcg-side-nav.moved-to-side {
	margin-left: -200px;
}

// The main, top navigation bar
#primaryNav, #userNav {
	.top-nav-bg  {
		background-color: $main-navbar-bg-color;
	}
	p, li > p.dropdown-toggle
	{
		color: $main-navbar-text-color;
	}
	p:hover {
		color: $main-navbar-text-color;
		text-decoration: underline;
		text-decoration-thickness: 2px;
    	text-underline-offset: 5px;
	}
	
	li.link-active p,
	li.link-active p:hover,
	li.link-active p:focus {
		// background-color: $main-navbar-active-item-bg-color;
		// color: $main-navbar-active-item-text-color;
		text-decoration: underline $main-navbar-active-item-accent-color;
		text-decoration-thickness: 2px;
		text-underline-offset: 5px;
	}

	//Dropdown menu text decorations
	ul.dropdown-menu > li.link-active p {
		text-decoration: none;
	}
	ul.dropdown-menu > li.link-active p:hover, li.link-active p:focus {
		color: $main-navbar-text-color;
		text-decoration: underline;
		text-decoration-thickness: 2px;
		text-underline-offset: 5px;
	}
}

// Navigation under the main, top nav bar
#secondaryNav {
	.top-nav-bg  {
		background-color: $secondary-navbar-bg-color;
		padding-left: 6px;
		padding-bottom: 6px;
	}
	.top-nav-bg li.link-active {			
		margin: 0px;
		padding-top: 6px;
	}
	p, li > p.dropdown-toggle
	{
		color: #ededed;
	}
	li.link-active p {
		color: $secondary-navbar-active-text-color;
		background:$main-navbar-active-item-accent-color;
		font-weight: bold;
	}
	li.link-active p:hover,
	li.link-active p:focus {
		font-weight: bold;
		cursor: pointer;
	}
	ul.nav > li:hover:not(.ellipse) {
		color: fff;
		text-decoration: underline;
		text-decoration-thickness: 2px;
		text-underline-offset: 5px;
		cursor: pointer;
	}
	ul.nav > li > p:hover {
		text-decoration: none;
	}
	ul.nav > li.link-active {
		text-decoration: none !important;
	}
}

pcg-header > nav.navbar {
    position: fixed;
    width: 100%;
    top: 0;
	z-index: 1049;
}

@media screen and (max-width: map-get($grid-breakpoints, "lg")) {
	pcg-header {
		position: static;
	}
}

@media screen and (max-width: map-get($grid-breakpoints, "sm")) {
	.main-container {
		display: block;
		width: 100%;
	}

	pcg-side-nav {
		position: static;
		height: auto;
		width: 100%;
		border-right: none;
	}
}
