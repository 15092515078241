@import "../variables";
@import "../bootstrap/mixins";

@import "nav";
@import "table";
@import "page-actions";

// Shifts everything down so not covered by secondary nav
.top-shift { margin-top: $nav-top-shift; }

.top-shift-fixed { 
	margin-top: $nav-top-shift;
	padding: 0 4rem;
}

/* This is used like an <hr> that you can put content into */
.work-flow-divider {
	color: $content-divider-text-color;
	font-size: 17px;
	padding: 12px;
	background-color: $content-divider-color;
	width: 100%;
}

/* For putting border around content under the
	.work-flow-divider or <pcg-collapsible-divider> */
.divider-content,
.tab-content {
	padding: 20px;
	border: 3px solid $content-divider-color;
}

.divider-content-s2,
.tab-content-s2 {
	padding: 0.9rem 2.1rem;
	border-radius: 0.625rem;
	box-shadow: 0rem 0.18rem 0.75rem rgba(0, 0, 0, 0.15);
}

/* Pads and centers buttons at the bottom of a page */
.bottom-buttons,
pcg-fixed-buttons {
	display: block;
	text-align: center;
	clear: both;
}

#informativeContent,
.informative-content {
	margin: 10px 0px;
	text-align: center;
}

#actionElements,
.action-elements {
	min-width: 150px;
	float: left;
	text-align: left;
	margin-right: 0.5rem;
	margin-bottom: 4px;
	vertical-align: top;

	@media screen and (max-width: map-get($grid-breakpoints, "md")) {
		float: none;
	}
}

.inline-add-btn {
	width: 100%;
	height: 2rem;
	font-size: 1rem !important;
	padding: 0.25rem 1.5rem !important;
}

/* Prevents text from wrapping under an action element button */
#descriptionText,
.descriptionText {
	white-space: normal;
	overflow: hidden;

	@media screen and (max-width: map-get($grid-breakpoints, "md")) {
		overflow: visible;
	}
}

#otherActions,
.other-actions {
	text-align: right;
	vertical-align: top;
	padding-right: 5px;
	right: 10px;
	float: right;
	padding-left: 20px;
	border-radius: 10px;
}

#smlFilters,
.smlFilters {
	text-align: right;
	vertical-align: top;
	padding-right: 5px;
	right: 10px;
	float: right;
	padding-left: 20px;
	border-radius: 10px;
	background-color: #f2f2f2;
	text-align: center;
	padding: 5px 10px 5px 22px;
}

#smlFilters > .ng-select,
#smlFilters > pcg-select .ng-select,
.smlFilters > .ng-select,
.smlFilters > pcg-select .ng-select {
	text-align: left;
}

#time-picker-wrapper {
	z-index: 1050 !important;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus,
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	background-color: $nav-tabs-link-active-bg;
	color: $nav-tabs-link-active-color;
}

.nav-tabs > li.active:hover > a,
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
	background-color: $nav-tabs-link-active-color;
	color: $nav-tabs-link-active-color;
}

.nav-tabs > li > a.nav-link {
	background-color: $nav-tabs-link-inactive-bg;
	border: 1px solid #dee2e6;
	// color: #dedede;
	color: $nav-tabs-link-inactive-color;
}

.nav-tabs > li:hover > a.nav-link {
	color: $nav-tabs-link-hover-color;
	background-color: $nav-tabs-link-hover-bg;
}

.bottom-buttons > .btn,
pcg-fixed-buttons .btn {
	margin: 10px;
}

.description-icon {
	margin-right: 0.75rem;
	opacity: 0.5;
}

.nav-background-color {
	background-color: $main-navbar-bg-color;
}

.nav-text-color {
	color: $main-navbar-text-color;
}

pcg-root:not(.is-logged-in) + #footer #versionHistory {
	display: none;
}

// Changes to make when the screen is bigger than the bootstrap lg breakpoint
@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
	.fixed-bottom-buttons {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: #fff;
		z-index: 1049;
	}
}

// Changes to make when the screen is smaller than the bootstrap lg breakpoint
@media screen and (max-width: map-get($grid-breakpoints, "lg")) {
	.top-shift { margin-top: 105px; }
	.nav-tabs .nav-item { width: 100%; }

	.description-icon {
		display: none;
	}
	.nav-tabs .nav-item {
		width: 100%;
	}
}

// Changes to make when the screen is bigger than the bootstrap xl breakpoint
@media screen and (min-width: 2000px) {
	.container {
		max-width: 90% !important;
	}
}

// Only show a single calendar on date range picker for small screens
@media screen and (max-width: map-get($grid-breakpoints, "sm")) {
	.ngb-dp-month + .ngb-dp-month {
		display: none;
	}
}

/** Print only styles **/
@media only print {
	pcg-header { display: none; }
	.top-shift { margin-top: 0; }
}

// Hover
.hoverLarger:hover {
	font-size: larger;
}

// CI Repack shared classes
.ci-status-pending {
	background-color: #8e44ad !important;
	color: white;
}
.ci-status-new-request {
	background-color: #f0a30a !important;
	color: white;
}
.ci-status-received {
	background-color: #f39c12 !important;
	color: white;
}
.ci-status-receiving-complete {
	background-color: #f39c12 !important;
	color: white;
}
.ci-status-repack-complete {
	background-color: #f39c12 !important;
	color: white;
}
.ci-status-shipped {
	background-color: #e67e22 !important;
	color: white;
}
.ci-status-invoiced {
	background-color: #2980b9 !important;
	color: white;
}
.ci-status-paid {
	background-color: #27ae60 !important;
	color: white;
}
.ci-status-in-dispute {
	background-color: #ff0000 !important;
	color: white;
}

.ci-invoice-status-unpaid {
	background-color: #ffffff !important;
	color: black !important;
}
.ci-invoice-status-paid {
	background-color: #27ae60 !important;
	color: white;
}
.ci-invoice-status-in-dispute {
	background-color: #ff0000 !important;
	color: white;
}
.ci-invoice-status-discrepancy {
	background-color: #f39c12 !important;
	color: white;
}

// ngb typeahead results
#ngb-live {
	display: none;
}

//Container
.pcg-container {
	padding: 1rem 2rem;
	border-radius: 0.5rem;
	box-sizing: border-box;
	border: 0.8px solid rgba(227, 228, 228, 0.721);
	box-shadow: 0px 1px 2px 0px rgba(119, 119, 119, 0.111);
	margin-right: 2px;
	margin-bottom: 1.5rem;
	overflow: visible;
	h1 {
		font-size: 20px;
	}
}

// Material tab styling
.mat-mdc-tab-group {
	width: 100%;
	height: fit-content;
	overflow-x: hidden;
	overflow-y: auto !important;
}

// Removed "height: 100%;" to correct y overflow issue preventing interaction with page footer links. Issue DEPCS-2916
.mat-mdc-tab-body-wrapper,
.mat-mdc-tab-body-active,
.mat-mdc-tab-body-content {
	overflow: inherit !important;
	width: 100%; 
}

.mat-mdc-tab-body-content {
	min-height: 50vh; // Fix for show hide being clipped
}

// Attempt to fix floating header issues with material tabs.
.mat-mdc-tab-body-wrapper .mat-mdc-tab-body-active .mat-mdc-tab-body-content:has(> div.mat-table-container) {
	width: 100%; // Hide the scroll
	max-height: 70vh; // Fix for nested table floating headers
	padding-right: 2rem; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */
}

.mat-tab-container {
	padding: 1rem 2rem;
	border-radius: 0.5rem;
	box-sizing: border-box;
	border: 0.8px solid rgba(227, 228, 228, 0.721);
	box-shadow: 0px 1px 2px 0px rgba(119, 119, 119, 0.111);
	margin-right: 2px;
	margin-bottom: 1.5rem;
	overflow: visible;
	h1 {
		font-size: 20px;
	}
}

.mat-mdc-tab {
	--mat-tab-header-active-ripple-color: #f8f8f900;
	--mat-tab-header-inactive-ripple-color: #465666;
	--mat-tab-header-active-focus-indicator-color: #339cd6;
	--mat-tab-header-active-hover-indicator-color: #339cd6;
	--mat-tab-header-active-label-text-color: #339cd6;
	--mat-tab-header-active-focus-label-text-color: #339cd6;
	--mat-tab-header-active-hover-label-text-color: #339cd6;
	--mdc-tab-indicator-active-indicator-color: #339cd6;
	--mdc-tab-indicator-active-indicator-height: 4px !important;

	font-size: var(--mdc-typography-button-font-size, 1rem) !important;
	// line-height: var(--mdc-typography-button-line-height, 1.5);
	font-weight: var(--mdc-typography-button-text-weight, 600) !important;
	border-radius: 0.5rem;
}

.mat-mdc-tab,
.mat-expansion-panel {
	font-family: var(--mdc-typography-button-font-family, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji");
	letter-spacing: var(
		--mdc-typography-button-letter-spacing,
		normal
	) !important;
}

.mat-mdc-tab:not(.mdc-tab--active) {
	overflow: hidden;
	border-radius: 0.5rem;
	font-weight: var(--mdc-typography-button-text-weight, 500) !important;
}

.mat-mdc-tab.mdc-tab--active {
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	background-color: rgba(42, 41, 41, 0.123) !important;
}
