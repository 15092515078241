.export-to-excel { @include inputImage(url('../../assets/images/actions/xlsxgreen.svg')); }
.report { @include inputImage(url('../../assets/images/icons/reporting.svg')); }
.show-history { @include inputImage(url('../../assets/images/actions/history.svg')); }
.print-page { @include inputImage(url('../../assets/images/actions/printBlue.svg')); }
.print-pdf { @include inputImage(url('../../assets/images/actions/Pdf2.svg')); }
.print-zpl { @include inputImage(url('../../assets/images/icons/zpl.png')); }
.order-page { @include inputImage(url('../../assets/images/icons/clipboard.svg')); }
.batch-page { @include inputImage(url('../../assets/images/icons/Invoice.svg')); }
.reset-job { 
	@include inputImage(url('../../assets/images/icons/reset.svg'));
	transform: scaleY(-1);
}
.box-list-page { @include inputImage(url('../../assets/images/icons/Box.svg')); }
.globe-stand { @include inputImage(url('../../assets/images/icons/Globe-stand.svg')); }
.bottle-log-list { @include inputImage(url('../../assets/images/actions/closed-trash.svg')); }
.actions { @include inputImage(url('../../assets/images/actions/actions.svg')); }
.magnifying-glass { @include inputImage(url('../../assets/images/icons/Magnifying_glass.svg')); }

button.export-to-excel:hover { background-color: lightgreen; }
button.show-history:hover { background-color: lightgrey; }
button.print-page:hover { background-color: lightBlue; }
button.closed-trash:hover { background-color: lightgrey; }
button.magnifying-glass:hover { background-color: lightgrey; }

button.page-action.export-to-pdf {
	@include inputImage(url('../../assets/images/actions/PdfSprite.svg'));
	background-position: 5px 5px;
}

button.export-to-pdf:hover {
	background-color: #f63434;
	background-position: 11px -41px;
	background-size: 60%;
}

//CI Bottlelog buttons
.open-trash {
	transform: scale(1.1);
	transition-duration: 0s !important;
}

.open-trash:hover {
	//background-color: rgb(189, 240, 189)
	background-color: transparent;
	border: none;
}

.closed-trash {
	@include inputImage(url('../../assets/images/actions/closed-trash.svg'));
	transform: scale(1.0);
	transition-duration: 0s !important;
	top: 13px !important;
}

//svg icons
#open-trash:hover { fill: #3C8A3C; }
#closed-trash:hover { fill: #d42424; }

//Icon for helpful tips
.lightbulb{
	@include inputImage(url('../../assets/images/icons/lightbulb.svg'));
	background-size: 75%;
}