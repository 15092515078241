@import '../variables';

/*********************************************
	Our labels 
*******************************************/
label.for,
legend.for {
	display: inline;
	font-size: 1rem;
	font-weight: $font-weight-bold;
}

legend.for { margin-bottom: 0; }
pcg-collapsible-divider label.for,
pcg-collapsible-divider legend.for { font-size: 17px; }

label.for:after {
	/*automagically line break without being block*/
	content: "\A";
	white-space: pre;
}

label.for + ngb-tooltip-window + input:not([type='radio']):not([type='checkbox']):not([type='file']),
label.for + ngb-tooltip-window + select,
label.for + input:not([type='radio']):not([type='checkbox']):not([type='file']),
label.for + select {
	margin-bottom: 0.5rem;
	display: block;
}

label.for + ngb-tooltip-window + input:not([type='radio']):not([type='checkbox']):not([type='file']) + .text-danger,
label.for + ngb-tooltip-window + select + .text-danger,
label.for + input:not([type='radio']):not([type='checkbox']):not([type='file']) + .text-danger,
label.for + select + .text-danger {
	margin-top: -0.5rem;
	display: block;
}

label.for + input[type='checkbox']:not(.form-check-input) { margin: 0 0.5rem 0 0.25rem; }

label.for + br,
legend.for + br {
	display: block;
	line-height: 0px;
	margin-top: -0.33rem;
	content: " ";
}

/*********************************************
	Fancy textboxes 
*******************************************/
input[type=text].email, 
input[type=email].email { @include inputImage(url('../../assets/images/informational/envelope.svg')); }

// This element selector only exists in one place: dynamic-form-field.component.html. 
// Commenting out for now on off chance we cast to another CSS selecter dtl.
// input[pcgZipCode] {
// 	max-width: 4.375rem;
// }

input[pcgPhone] { @include inputImage(url('../../assets/images/informational/phone.svg')); }
input[pcgPhoneExt] { @include inputImage(url('../../assets/images/informational/phone.svg')); }
input[pcgPercentage] { max-width: 6rem; }

/** Make some stupid big checkboxes and radio buttons **/
input[type="checkbox"], 
input[type="radio"] { transform: scale(1.4); }

/*********************************************
	Touch checkboxes
*******************************************/
input[type="checkbox"].touch {
	z-index: 2;
	position: relative;
	top: -1px;
}

input[type="checkbox"].touch + label {
	display: inline-block;
	background-color: lightgrey;
	border: 1px solid #ccc;
	text-align: right;
	padding: 10px 20px 10px 30px;
	margin-left: -20px;
	position: relative;
	z-index: 1;
	cursor: pointer;
	border-radius: 3px;
}

input[type="checkbox"]:checked.touch + label {
	background-color: #40D47D;
	color: #fff;
}

/*********************************************
	Touch radio buttons
*******************************************/
input[type="radio"]:checked.touch + label {
	background-color: #40D47D;
	color: #fff;
}

input[type="radio"].touch + label {
	display: inline-block;
	background-color: lightgrey;
	border: 1px solid #ccc;
	text-align: center;
	padding: 10px;
	margin: 0px 10px 10px 0;
	position: relative;
	z-index: 2;
	cursor: pointer;
	min-width: 100px;
	border-radius: 3px;
}

input[type="radio"].touch { display: none; }

/** Small controls **/
input[type="text"].small-control, 
input[type="password"].small-control,
input[type="search"].small-control, 
input[type="number"] {
	width: 6.5rem;
	margin-bottom: 0;
}

select.small-control { width: 6.5rem; }

/** Style for larger numeric or currency inputs **/
input[type="number"].large-control, 
input[type="text"].large-control { width: 7rem; }

/** Make bootstrap radio buttons / checkboxes have pointer **/
.form-check-label { cursor: pointer; } 
.filter-block > .ng-select-multiple, 
.filter-block > pcg-select .ng-select-multiple { max-width: 300px; }
.filter-block > .ng-select, 
.filter-block > pcg-select .ng-select { min-width: 200px; }
#smlFilters > .ng-select, 
#smlFilters > pcg-select .ng-select { min-width: 200px; }
.preset-button + .popover { min-width: 175px !important; }

/** Large controls **/
input[type="number"].large-control { width: 6rem; }

/** Remove type='number' arrows */
/*****************************************/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
	appearance: textfield;
}
/*****************************************/

// CSS to make disabled form elements look like spans
.disabled-as-text {
	input.form-control[type=text]:disabled,
	textarea.form-control:disabled,
	input.form-control[type=number]:disabled,
	select:disabled {
		border: none;
		padding: 0;
		margin: 0;
		background: none;
		height: initial;
		min-height: initial;
		color: #212529;
		appearance: none;
	}

	// update readonly dropdowns to remove styles for dropdown so values appear as text
	.ng-select.ng-select-disabled { display: none; }

	.ng-select.ng-select-disabled + .disabled-only-text {
		display: inline;
		cursor: default;
	}
}

.disabled-only-text { display: none; }

// Do not show browser generated box shadows on invalid elements
:invalid { box-shadow: none; }
:-moz-submit-invalid { box-shadow: none; }
:-moz-ui-invalid { box-shadow: none; }

// For disabled font awesome icons
.fa-disabled {
	pointer-events: none;
	cursor: default;
	opacity: 0.5;
}

//ui-switch

.form-ui-switch {
    display: flex;
    align-items: center;
}

.form-ui-switch-label { margin-left: 10px; }
.disabled  { cursor: not-allowed !important; }

// Text editor
trix-editor { border-radius: 0.32rem !important; }
trix-toolbar .trix-button-row { flex-wrap: wrap !important; }