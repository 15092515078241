@use "sass:math";

@import "../variables";
@import "../bootstrap/mixins";
@import "../../../node_modules/@ng-select/ng-select/themes/default.theme.css";

@for $i from 1 through 12 {
	.fb-#{$i} { width: percentage(math.div($i, 12)); }
}

/* Tooltips */
/* This Angular directive is part of ng bootstrap */
[ngbTooltip],
[ng-reflect-ngb-tooltip],
[labelTooltip] > label:first-child {
	cursor: help;
	text-decoration: none;
	border-bottom: 1px dashed lightBlue;
}

[ngbTooltip].ignoreTooltipStyle { border-bottom: none; }

// Specify toastr-dialog width for all devices
.ngx-toastr { width: 500px !important; }
.tooltip-inner { max-width: 400px !important; }

/* Block links */
/* These are typically used to go to reports. */
.block-link {
	padding: 5px 10px;
	display: inline-block;
}

.block-link:hover {
	padding: 4px 9px;
	border: 1px solid blue;
	cursor: pointer;
}

/* Validation */
/* Add a red asterisk before required fields */
.required-field:before {
	color: red;
	content: "* ";
	margin-left: -10px;
}

/* Collapser rows */
/* This is used for tables with child rows */
/* It goes on the parent tr */
.row-collapser-down td:first-child { cursor: pointer; }

/* Center our modals by default */
.modal-dialog {
	margin: 0 auto !important;
	display: flex;
	align-items: center;
	min-height: calc(100% - (#{$modal-dialog-margin} * 2));

	// Ensure `modal-dialog-centered` extends the full height of the view (IE10/11)
	&::before {
		display: block; // IE10
		height: calc(100vh - (#{$modal-dialog-margin} * 2));
		content: "";
	}
}

// We have .font-weight-bold already, but because it is
// familiar and shorter, I'm defining .bold too
.bold { font-weight: $font-weight-bold !important; }

// Bold our merged buttons by default
.input-group-append .btn { font-weight: $font-weight-bold; }

/** Filter CSS **/
.filters {
	background-color: #dfdfdf;
	margin: 0;
	padding: 10px;
	text-align: center;
}

.filters .filter-block { padding: 10px 20px; }

.filter-block {
	display: inline-block;
	margin: 10px;
	text-align: left;
	vertical-align: top;
}

.filter-block.compressed {
	margin-left: 5px;
	margin-right: 5px;
	padding: 0px;
}

.filters .btn.btn-filters {
	display: block;
	margin: auto;
}

/** Page actions **/
input[type="button"].page-action,
input[type="submit"].page-action,
button.page-action,
.btn.page-action {
	width: 60px;
	height: 60px;
	background-color: transparent;
	border: none;
	transition-property: background-color, border;
	transition-duration: 0.4s;
	margin: -15px 0 0 0;
	padding: 5px;
	background-color: transparent;
	background-size: 70%;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 50%;
	vertical-align: top;
	outline: 0;
	border: 6px double transparent;
	position: relative;
	top: 10px;
}

#pageActions,
.page-actions,
.page-actions .page-action { float: right; }

.page-action:hover {
	border: 5px double #fff;
	background-color: lightgrey;
	cursor: pointer;
}

.page-action svg,
.page-action img {
	width: 40px !important;
	height: 40px !important;
}

svg:not(:root) { overflow: hidden; }
.h1-container { @include clearfix(); }

.btn.btn-outline-secondary {
	background-color: white;
	color: #212529;
}

// Recaptcha
.grecaptcha-badge { z-index: $zindex-recaptcha; }
.min-width-100px { min-width: 100px; }
.min-width-175px { min-width: 175px; }
.min-width-200px { min-width: 200px; }

// Changes to make when the screen is smaller than the bootstrap md breakpoint
@media screen and (max-width: map-get($grid-breakpoints, "md")) {
	.filters .filter-block { width: 100%; }
	.tooltip-inner { max-width: 200px !important; }
}

// Exclude elements from printing
@media print {
	.col-sm-1,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12 {
		float: none;
		width: 100%;
	}

	.no-print { display: none !important; }
}

// Custom sizing for modal inbetween md and lg default sizes
.modal-md-lg .modal-dialog {
	max-width: 750px;
	width: 750px !important;
}

// Underlines Log and Exception messages in PCG Silkier History
.underline-blue {
	text-decoration: underline;
	color: blue;
	cursor: pointer;
}

// Additions to bootstrap width classes
.w-10 { width: 10%; }
.w-20 { width: 20%; }
.w-30 { width: 30%; }
.w-40 { width: 40%; }
.w-60 { width: 60%; }
.w-70 { width: 70%; }
.w-80 { width: 80%; }
.w-90 { width: 90%; }
.w-95 { width: 95%; }
.w-98 { width: 98%; }
.rounded-top-left { border-top-left-radius: 0.32rem !important; }
.rounded-top-right { border-top-right-radius: 0.32rem !important; }
.rounded-bottom-left { border-bottom-left-radius: 0.32rem !important; }
.rounded-bottom-right { border-bottom-right-radius: 0.32rem !important; }
.not-allowed { cursor: not-allowed !important; }

// Added by @angular/material
// Not a fan of it overriding all our styling.
// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// IMPORTANT: Put styles relating to overriding Material navigation classes (for conversations only) here:
// Shouldn't affect global Material sidenav, only the Material sidenav in the 'help-desk-ticket-edit' component (or sidenavs with these classes applied)
.conversations-container,
.conversations-other-content {
	.mat-drawer-backdrop {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
	}
}
.conversations-sidenav {
	.mat-drawer-inner-container { overflow: hidden !important; } //hides scrollbar for ticket conversations pop-out
}

// Knowledge Base - Mat sidenav
.knowledge-base-container {
	.mat-drawer-backdrop { visibility: hidden !important; }
	.mat-drawer-inner-container { overflow: hidden; }
	// .mat-drawer-inner-container { padding: 0 .5rem; }
	// .mat-drawer-inner-container::-webkit-scrollbar { width: 10px; }
	// .mat-drawer-inner-container::-webkit-scrollbar-track { background: #e0e0e0; }
	// .mat-drawer-inner-container::-webkit-scrollbar-thumb { background: #bbb; border-radius: .1rem; }
	// .mat-drawer-inner-container::-webkit-scrollbar-thumb:hover { background: #aaaaaa; }
}

/*------ Color Picker ------*/
.color-picker {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	height: $input-height;
	background-color: transparent;
	border: $input-border-width solid $input-border-color;
	cursor: pointer;
}

.color-picker::-webkit-color-swatch {
	border-radius: 15px;
	border: none;
}

.color-picker::-moz-color-swatch {
	border-radius: 15px;
	border: none;
}

// mat-progress-bar
mat-progress-bar {
    --mdc-linear-progress-active-indicator-height: 0.75rem;
    --mdc-linear-progress-track-height: 0.75rem;
    border-radius: 5px;
}

mat-progress-bar .mdc-linear-progress__bar-inner  {
	--mdc-linear-progress-active-indicator-color: #088725;
	border-radius: 5px;
}

mat-progress-bar .mdc-linear-progress__buffer-bar {
	--mdc-linear-progress-track-color: #fff;
	border: 1px solid rgb(199, 199, 199);
	border-radius: 5px;
}

// Mat side nav
.mat-drawer-inner-container {
	height: auto !important; // Gets rid of scrollbar in side nav
	margin-bottom: 2rem;
}

.cursor-pointer:hover { cursor: pointer !important; }

.shadow-top {
    box-shadow: 0px -10px 10px -15px #111;    
}
.shadow-bottom {
    box-shadow: 0px 10px 10px -15px #111;    
}
.shadow-left {
    box-shadow: -10px 0px 10px -15px #111;    
}
.shadow-right {
    box-shadow: 10px 0px 10px -15px #111;    
}

.fill-container {
	height: 98%;
	height: -webkit-fill-available;  /* WebKit-based browsers */
	height: stretch;                 /* Standard property */

	width: 98%;
	width: -webkit-fill-available;  /* WebKit-based browsers */
	width: stretch;                 /* Standard property */
}